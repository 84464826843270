<template>
  <div
    v-if="accounts.length > 0"
    class="h-64 hidden bg-white p-0 rounded shadow select-none z-[100]"
    :class="{ '!flex flex-col': showAccounts }"
    data-cy="profile_menu_account_list"
    @mouseleave="onMouseLeave"
  >
    <InputField
      id="subaccount_search_field"
      type="text"
      rounded
      prefix-icon="fa-magnifying-glass"
      :placeholder="$t('shared.placeholder.search_for_subaccount')"
      class="w-full py-4 px-6"
      :value="search_query"
      @inputChange="handleSearch"
    />
    <div class="h-full flex-col overflow-y-scroll border-t border-neutral-80">
      <template v-if="filteredAccounts.length > 0">
        <div
          v-for="account in filteredAccounts"
          :key="account.attributes.team_identifier"
          data-cy="account_list_subbacount"
          class="py-4 px-6 border-b border-neutral-80 hover:bg-neutral-50"
          @click="handleAccountSwitch(account.attributes.team_identifier)"
        >
          <TextRegular class="cursor-pointer" bold>
            <p>{{ capitalizeFirstLetter(account.attributes.company_name) }}</p>
          </TextRegular>
          <TextSmall
            class="cursor-pointer !text-primary-100 underline desktop:no-underline desktop:hover:underline"
          >
            {{ account.attributes.team_identifier }}
          </TextSmall>
        </div>
      </template>
      <template v-else>
        <div class="py-4 px-6">
          <TextRegular>
            <p data-cy="no_results_message">
              {{ $t('shared.placceholder.no_result_found') }}
            </p>
          </TextRegular>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextSmall from '@/components/shared/font/text/TextSmall'
import InputField from '@/components/shared/input/InputField.vue'
import { capitalizeFirstLetter } from '@/helpers/format'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const $t = i18n.t
const store = useStore()
const route = useRoute()
const router = useRouter()

const emit = defineEmits(['setShowAccounts'])
const props = defineProps({
  showAccounts: Boolean
})

const search_query = ref('')

const currentUserAccounts = computed(
  () => store.state.workspace.currentUserAccounts
)
const accounts = computed(() => {
  if (currentUserAccounts.value.length > 0) {
    return currentUserAccounts.value.filter(
      (account) =>
        account.attributes.team_identifier !== route.params.teamIdentifier
    )
  } else {
    return []
  }
})
const filteredAccounts = computed(() => {
  if (!search_query.value) {
    return accounts.value
  }
  return accounts.value.filter((account) => {
    const { company_name, team_identifier } = account.attributes

    const lowercasedSearchQuery = search_query.value.toLowerCase()

    return (
      company_name.toLowerCase().includes(lowercasedSearchQuery) ||
      team_identifier.toLowerCase().includes(lowercasedSearchQuery)
    )
  })
})
const clearUserInvites = () => store.commit('invitations/clearUserInvites')

function handleAccountSwitch(team) {
  search_query.value = ''
  emit('setShowAccounts', false)
  clearUserInvites()
  router.push({
    name: 'clientOverview',
    params: { teamIdentifier: team }
  })
}

function handleSearch(inputValue) {
  search_query.value = inputValue.value
}

function onMouseLeave() {
  search_query.value = ''
  emit('setShowAccounts', false)
}
</script>

<template>
  <div
    class="header__inner w-full !grid desktop:!hidden items-center z-[1] grid-cols-[1fr_auto] grid-rows-[1fr_1fr] mobileLandscape:grid-rows-[1fr] desktop:grid-cols-[1fr_auto_auto_auto_auto]"
  >
    <Breadcrumbs />
    <Sidebar />
    <HeaderMobileBurger />
  </div>
</template>
<script setup>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs'
import HeaderMobileBurger from '@/components/header/HeaderMobileBurger'
import Sidebar from '@/components/sidebar/Sidebar'
import { useRoute } from 'vue-router'

const route = useRoute()
</script>
